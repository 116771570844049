<template>
	<li class="node-tree">
    <span class="label">{{ node.label }}</span>

    <ol v-if="node.children && node.children.length">
      <node v-for="(child, i) in node.children" :key='i' :node="child" />
    </ol>
  </li>
</template>

<script>
	export default {
		name: "node",
		props: {
			node: Object
		}
	}
</script>
