<template>
	<div>
		<div class='a-booking a-content a-content-bigger-box'>
			<div class='app-container app-container-fluid a-container'>
				<div class='a-booking-container'>
					<div class='a-booking-content'>
						<div class='a-booking-box'>
							<p class='a-content-title'>{{$t("privacy_policy.title")}}</p>

							<div class='a-content-block'>
								<p>{{$t("privacy_policy.text")}}</p>
							</div>

							<div class='a-content-block'>
								<ol class="tree-list">
									<node-tree
										v-for='(node, i) in $t("privacy_policy.list")' :key='i'
										:node='node'
										style='margin-bottom: 15px'
									/>
								</ol>
							</div>
						</div>
					</div>
					<div class="a-booking-banner"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import nodeTree from '@/views/hc/components/nodeTree';

	export default {
		components: { nodeTree }
	}
</script>
